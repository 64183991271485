import { request } from './request'

// 获取订单列表
export function getOrderList (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/order/admin/list',
    params
  })
}

// 获取物流进度
export function getOrderDetail (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/order/detail',
    params
  })
}

// 修改订单数据
export function editOrderRequest (id, data) {
  return request({
    url: `/orders/${id}`,
    method: 'put',
    data
  })
}

// 提交快递信息
export function deliveryOrder (data) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/order/update/delivery',
    method: 'post',
    data: data
  })
}

// 更新订单状态
export function updateOrderStatus (data) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/order/update/delivery',
    method: 'post',
    data: data
  })
}

// 获取交易列表
export function getTradeList (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/order/trade/list',
    params
  })
}

// 获取交易列表
export function getExpressTrace (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/express/trace',
    params
  })
}
