<template>
  <el-form :model="listQuery" ref="listQuery" :inline="true" class="demo-form-inline">
    <!--<el-form-item label="用户" prop="mobile">
      <el-input placeholder="支付人手机号" v-model="listQuery.mobile"></el-input>
    </el-form-item>-->
    <el-form-item label="日期范围" prop="date">
      <el-date-picker
        v-model="listQuery.date"
        type="daterange"
        align="right"
        prop="date"
        value-format="timestamp"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="search">查询</el-button>
      <el-button @click="resetForm('listQuery')">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'TradeSearch',
  props: {
    listQuery: {
      type: Object,
      default () {
        return {
          mobile: '',
          date: '',
          page: 1, // 当前页
          size: 10 // 每页显示多少条数据
        }
      }
    }
  },
  methods: {
    // 点击搜索按钮时，通知外部组件重新获取交易列表
    search () {
      this.$emit('trade-list')
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style scoped>

</style>
