import { render, staticRenderFns } from "./TradePagination.vue?vue&type=template&id=74a590c4&scoped=true"
import script from "./TradePagination.vue?vue&type=script&lang=js"
export * from "./TradePagination.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74a590c4",
  null
  
)

export default component.exports