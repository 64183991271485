<template>
  <el-table class="custom-trade-table" :data="tradeList" border stripe :cell-style="changeCellStyle">
    <!--<el-table-column prop="mobile" label="用户" width="150px"></el-table-column>
    <el-table-column prop="buyerLogonId" label="支付宝账号" width="150px"></el-table-column>-->
    <el-table-column prop="tradeStatus" label="交易状态" width="120px">
      <template slot-scope="scope">{{ scope.row.tradeStatus | tradeFormat }}</template>
    </el-table-column>
    <!--<el-table-column prop="trade_no" label="交易编号"></el-table-column>-->
    <el-table-column prop="orderSn" label="订单编号"></el-table-column>
    <el-table-column prop="createTime" label="交易时间">
      <template slot-scope="scope">{{ scope.row.createTime | dateFormat }}</template>
    </el-table-column>
    <el-table-column prop="amount" label="交易类型" width="120px">
      <template slot-scope="scope">{{ scope.row.isRefund | tradeTypeFormat }}</template>
    </el-table-column>
    <el-table-column prop="amount" label="交易金额" width="120px">
      <template slot-scope="scope">
        <span v-if="scope.row.isRefund===1" style="color:red">{{ scope.row.refundAmount | floatFormat }}</span>
        <span v-else>{{ scope.row.buyerPayAmount | floatFormat }}</span>
      </template>
      <!--<template slot-scope="scope" v-show="scope.row.isRefund===1">{{ scope.row.buyerPayAmount | floatFormat }}</template>
      <template slot-scope="scope" v-show="scope.row.isRefund===0">{{ scope.row.buyerPayAmount | floatFormat }}</template>-->
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'TradeTable',
  props: {
    tradeList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  filters: {
    tradeFormat (value) {
      switch (value) {
        case 'WAIT_BUYER_PAY':
          return '等待付款'
        case 'TRADE_CLOSED':
          return '交易关闭'
        case 'TRADE_SUCCESS':
          return '交易成功'
        case 'TRADE_FINISHED':
          return '交易结束'
        case 'SUCCESS':
          return '交易成功'
      }
    },
    tradeTypeFormat (value) {
      switch (value) {
        case 0:
          return '支付'
        case 1:
          return '退款'
      }
    }
  },
  methods: {
    // 监听子组件发射的事件，获得最新的tradeList和total信息
    updateTradeList () {
      this.$emit('trade-list')
    },
    changeCellStyle ({ row, column, rowIndex, columnIndex }) {
      let cellStyle
      switch (row.tradeStatus) {
        case 'TRADE_SUCCESS': case 'SUCCESS':
          cellStyle = 'color:green'
          break
        default:
          cellStyle = 'color:red'
      }
      if (column.label === '交易状态') {
        return cellStyle
      }
    }
  }
}
</script>

<style scoped>

</style>
