<template>
  <div>
    <!-- 顶部面包屑导航 -->
    <breadcrumb-nav>
      <template v-slot:firstMenu>订单管理</template>
      <template v-slot:secondMenu>交易明细</template>
    </breadcrumb-nav>
    <el-card class="box-card">
      <!-- 顶部搜索与添加按钮 -->
      <trade-search :listQuery="listQuery" @trade-list="updateTradeList"/>
      <!-- 展示的表格 -->
      <trade-table :trade-list="tradeList" :list-query="listQuery" @trade-list="updateTradeList"/>
      <!-- 分页 -->
      <trade-pagination :list-query="listQuery" :total="total" @page-size-change="handleSizeChange"
                        @current-page-change="handleCurrentChange"/>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import TradeSearch from './tradeChildComponents/TradeSearch'
import TradeTable from './tradeChildComponents/TradeTable'
import TradePagination from './tradeChildComponents/TradePagination'
import { getTradeList } from '../../network/order'

export default {
  name: 'Trade',
  components: {
    BreadcrumbNav,
    TradeSearch,
    TradeTable,
    TradePagination
  },
  data () {
    return {
      listQuery: {
        mobile: '',
        date: '',
        page: 1,
        size: 10
      },
      tradeList: [],
      total: 0
    }
  },
  created () {
    this.getTradeList()
  },
  methods: {
    updateTradeList () {
      this.getTradeList()
    },
    // 监听pageSize改变的事件，并发送新的网络请求
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getTradeList()
    },

    // 监听页码改变的事件，并发送新的网络请求
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getTradeList()
    },
    getTradeList () {
      const query = {
        page: this.listQuery.page,
        size: this.listQuery.size,
        mobile: this.listQuery.mobile
      }
      if (this.listQuery.date !== null && this.listQuery.date !== undefined && this.listQuery.date.length > 1) {
        query.from = this.listQuery.date[0]
        query.to = this.listQuery.date[1]
      }
      getTradeList(query).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('交易列表获取失败', 'error')
        }
        this.tradeList = result.data.list
        this.total = result.data.total
        console.log('trade list:', this.tradeList)
      })
    }
  }
}
</script>

<style scoped>

</style>
